<template>
    <div class="relative">
        <label
            v-if="label"
            class="mb-2 block text-sm font-medium text-grey-600"
        >
            {{ label }}
            <span
                v-if="isRequired"
                title="required"
                class="text-sm font-normal text-[#FF5959]"
            >
                *
            </span>
        </label>
        <div class="relative pb-5">
            <!-- <div
                v-if="errorMessage && enableTooltip"
                :id="name + 'tooltip'"
                role="tooltip"
                :class="
                    errorClass +
                    ' absolute top-[-3px] z-20 inline-block -translate-y-full whitespace-nowrap rounded-lg bg-grey-400 px-3 py-2 text-[10px] text-white shadow-sm'
                "
            >
                {{ $t(errorMessage) }}
                <div class="tooltip-arrow" data-popper-arrow></div>
            </div> -->
            <input
                :id="name + '_id'"
                class="block w-full appearance-none rounded-lg border p-2.5 focus:ring-0"
                autocomplete="off"
                :name="name"
                :class="[
                    classes,
                    disabled ? 'bg-bigg-gray' : '',
                    errorMessage
                        ? 'border-[#FF380D] focus:border-[#FF380D]'
                        : 'border-[#ECECEC] focus:border-bigg-yellow',
                ]"
                :type="showPass ? 'text' : 'password'"
                :value="inputValue"
                :placeholder="placeholder"
                :maxlength="maxlength"
                @input="handleChange"
                @blur="handleBlur"
            />
            <template v-if="enableToggle">
                <div
                    v-if="!showPass"
                    class="absolute right-0 top-1/2 -mt-2.5 flex -translate-y-1/2 cursor-pointer items-center pr-3"
                >
                    <img
                        :src="`${
                            useRuntimeConfig().public.staticUrl
                        }/assets/img/icons/eye_hide.svg`"
                        alt="eye hide"
                        @click="showPass = !showPass"
                    />
                </div>
                <div
                    v-else
                    class="absolute right-0 top-1/2 -mt-2.5 flex -translate-y-1/2 cursor-pointer items-center pr-3"
                >
                    <img
                        :src="`${
                            useRuntimeConfig().public.staticUrl
                        }/assets/img/icons/eye_show.svg`"
                        alt="eye show"
                        @click="showPass = !showPass"
                    />
                </div>
            </template>
            <span
                v-if="errorMessage"
                class="absolute bottom-0 text-xs text-error"
            >
                {{ $t(errorMessage) }}
            </span>
        </div>

        <!-- <span class="text-red text-xs" v-show="errorMessage || meta.valid">
            {{ errorMessage || successMessage }}
        </span> -->
        <!-- <span class="text-red text-xs" v-if="errorMessage">
            {{ $t(errorMessage) }}
        </span> -->
    </div>
</template>
<script setup>
import { toRef } from 'vue'
import { useField } from 'vee-validate'

const props = defineProps({
    type: {
        type: String,
        default: 'password',
    },
    value: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        required: true,
    },
    enableTooltip: {
        type: Boolean,
        default: true,
    },
    classes: {
        type: String,
        default: '',
    },
    enableToggle: {
        type: Boolean,
        default: true,
    },
    label: {
        type: String,
        required: true,
    },
    successMessage: {
        type: String,
        default: '',
    },
    placeholder: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    errorClass: {
        type: String,
        default: 'right-0',
    },
    maxlength: {
        type: Number,
        default: 32,
    },
})
const showPass = ref(false)
const name = toRef(props, 'name')

const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    // meta,
} = useField(name, undefined, {
    initialValue: props.value,
})
</script>
